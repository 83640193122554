import { Dispatch, MutableRefObject, RefObject, SetStateAction, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { ChatMessage } from '@/aiAssistant/aiAssistant.types';
import { doTrack } from '@/track/track.service';
import { useTranslation } from 'react-i18next';
import { getCsrfToken } from '@/utilities/auth.utilities';
import { APPSERVER_API_CONTENT_TYPE } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { getOriginUrl, getOriginLabel, generateRequestId } from '@/utilities/http.utilities';

type Result = {
  submitPrompt: (prompt: string) => void;
  isRunning: boolean;
};

const getAPIResponseStream = async (input: string, chatId: string) => {
  const body = {
    prompt: input,
    chat_id: chatId,
    stream: true,
    llm_model: 'gpt-4',
    agentType: 'formula',
  };

  // We use await / fetch here instead of Axios because Axios doesn't support streaming responses as robustly.

  const headers = {
    'content-type': APPSERVER_API_CONTENT_TYPE,
    [SeeqNames.API.Headers.Csrf]: getCsrfToken(),
    [SeeqNames.API.Headers.RequestOrigin]: 'Analysis',
    [SeeqNames.API.Headers.RequestOriginURL]: getOriginUrl(),
    [SeeqNames.API.Headers.RequestOriginLabel]: getOriginLabel(),
    [SeeqNames.API.Headers.Async]: true,
    [SeeqNames.API.Headers.RequestId]: generateRequestId(),
  };

  const response = await fetch('/api/assistants/llm/chat', {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.body;
};

async function* decodeStreamToText(data: ReadableStream<Uint8Array> | null): AsyncIterableIterator<string> {
  if (!data) return;

  const reader = data.getReader();
  const decoder = new TextDecoder();

  while (true) {
    const { value, done } = await reader.read();
    if (done) break;

    if (value) {
      try {
        yield decoder.decode(value);
      } catch (error) {
        console.error(error);
      }
    }
  }
}

/**
 * Custom hook to handle streaming API responses.
 *
 * @param chatId The chat ID to use for the chat.
 * @param setMessages The function to update the messages in the chat.
 * @param display The ref to the chat display.
 * @returns An object containing the messages, input, and handlers for the chat.
 */
export const useChatStream = (
  chatId: MutableRefObject<string>,
  setMessages: Dispatch<SetStateAction<ChatMessage[]>>,
  display: RefObject<HTMLDivElement>,
): Result => {
  const [isRunning, setIsRunning] = useState(false);
  const { t } = useTranslation();
  const BOT_ERROR_MESSAGE = t('Something went wrong fetching AI response.');

  const addMessage = (newMessage: ChatMessage) => {
    setMessages((previousValue) => {
      return [...previousValue, newMessage];
    });
  };

  const addTrackedUserMessageToChat = (input: string) => {
    const messageId = uuid();
    addMessage({
      role: 'user',
      dialog: input,
      id: messageId,
      chatId: chatId.current,
    });

    doTrack('AiAssistant', 'search', {
      prompt: input,
      message_id: messageId,
      chat_id: chatId.current,
      location: 'Formula',
      agent_type: 'Formula',
    });

    requestAnimationFrame(scrollToBottom);
  };

  const addBotMessageToChat = (message: string) => {
    addMessage({
      role: 'bot',
      dialog: message,
      id: uuid(),
      chatId: chatId.current,
    });
  };

  const appendMessageToChat = (message: string) => {
    setMessages((previousValue) => {
      previousValue[previousValue.length - 1].dialog += message;
      return [...previousValue];
    });

    requestAnimationFrame(scrollToBottom);
  };

  const fetchAndUpdateAIResponse = async (input: string) => {
    addBotMessageToChat('');
    setIsRunning(true);

    const stream = await getAPIResponseStream(input, chatId.current);
    if (!stream) throw new Error();

    for await (const message of decodeStreamToText(stream)) {
      appendMessageToChat(message);
    }

    setIsRunning(false);
  };

  const submitPrompt = async (prompt: string) => {
    const cleansedPrompt = prompt.trim();
    if (!cleansedPrompt || isRunning) return;

    addTrackedUserMessageToChat(prompt);

    try {
      await fetchAndUpdateAIResponse(prompt);
    } catch {
      addBotMessageToChat(BOT_ERROR_MESSAGE);
      setIsRunning(false);
    }
  };

  const scrollToBottom = () => {
    display.current?.scrollTo({
      top: display.current.scrollHeight,
    });
  };

  return { submitPrompt, isRunning };
};
