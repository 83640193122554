import React, { useRef, useState } from 'react';
import { useChatStream } from '@/aiAssistant/useChatStream';
import { AiAssistantPrompt } from '@/aiAssistant/AiAssistantPrompt.molecule';
import { v1 as timeOrderedUUID } from 'uuid';
import { AiAssistantChatView } from '@/aiAssistant/AiAssistantChatView.molecule';
import { ChatMessage } from '@/aiAssistant/aiAssistant.types';

interface AiAssistantProps {
  insertFormulaSnippet: (snippet: string) => void;
}

export const AiAssistant: React.FunctionComponent<AiAssistantProps> = ({ insertFormulaSnippet }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const chatId = useRef(timeOrderedUUID());
  const displayRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;

  const { submitPrompt, isRunning } = useChatStream(chatId, setMessages, displayRef);

  return (
    <div className="flexRowContainer flexFill">
      <AiAssistantChatView
        messages={messages}
        submitPrompt={submitPrompt}
        insertFormulaSnippet={insertFormulaSnippet}
        ref={displayRef}
      />
      <AiAssistantPrompt submitPrompt={submitPrompt} isRunning={isRunning} />
    </div>
  );
};
