import React from 'react';
import { ChatMessage } from '@/aiAssistant/aiAssistant.types';
import { Message } from '@/aiAssistant/Message.molecule';
import { AiAssistantIntroduction } from '@/aiAssistant/AiAssistantIntroduction.atom';

interface AiAssistantChatViewProps {
  messages: ChatMessage[];
  submitPrompt: (prompt: string) => void;
  insertFormulaSnippet: (snippet: string) => void;
}

export const AiAssistantChatView = React.forwardRef<HTMLDivElement, AiAssistantChatViewProps>(
  ({ messages, submitPrompt, insertFormulaSnippet }, ref) => {
    return (
      <div className="flexColumnContainer flexFill">
        <div ref={ref} className="flexRowContainer overflowAuto mt15 pr5 width-maximum">
          {messages.length === 0 && <AiAssistantIntroduction submitPrompt={submitPrompt} />}

          {messages.map((message) => (
            <Message key={message.id} message={message} insertFormulaSnippet={insertFormulaSnippet} />
          ))}
        </div>
      </div>
    );
  },
);
