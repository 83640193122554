import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Icon } from '@seeqdev/qomponents';

interface MarkdownSnippetProps {
  markdown: string;
  insertFormulaSnippet: (snippet: string) => void;
}

export const MarkdownSnippet: React.FunctionComponent<MarkdownSnippetProps> = ({ markdown, insertFormulaSnippet }) => {
  return (
    <ReactMarkdown
      components={{
        code({ node, inline, className, children, ...props }) {
          return !inline ? (
            <>
              <div className="flexColumnContainer">
                <SyntaxHighlighter
                  {...props}
                  style={oneLight}
                  PreTag="div"
                  customStyle={{ minWidth: 'calc(100% - 20px)' }}>
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
                <Icon
                  icon="fc-copy"
                  tooltip="COPY"
                  extraClassNames="cursorPointer pl5 pt7"
                  testId="exampleCopyBtn"
                  onClick={() => insertFormulaSnippet(String(children))}
                />
              </div>
            </>
          ) : (
            <code {...props} className={className}>
              {children}
            </code>
          );
        },
      }}
      remarkPlugins={[remarkGfm]}>
      {markdown}
    </ReactMarkdown>
  );
};
