import * as React from 'react';
import { ChatMessage } from '@/aiAssistant/aiAssistant.types';
import { MarkdownSnippet } from '@/aiAssistant/MarkdownSnippet.molecule';
import { Icon } from '@seeqdev/qomponents';

interface MessageProps {
  message: ChatMessage;
  insertFormulaSnippet: (snippet: string) => void;
}

export const Message: React.FunctionComponent<MessageProps> = ({ message, insertFormulaSnippet }) => {
  const messageIcon = message.role === 'user' ? 'user' : 'robot';
  return (
    <div key={message.id} data-testid="message" className={`flexColumnContainer pt15 pr15 pl15 pb10 ${message.role}`}>
      <div className="flexColumnContainer">
        <Icon icon={`fa-${messageIcon}`} testId={messageIcon} extraClassNames="pl5 pt3 pr10" />
      </div>
      <div className="flexRowContainer flexFill">
        <MarkdownSnippet markdown={message.dialog} insertFormulaSnippet={insertFormulaSnippet} />
      </div>
    </div>
  );
};
