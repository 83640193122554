import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import { Button } from '@seeqdev/qomponents';
import { t } from 'i18next';
import { aiAssistantEnabled } from '@/services/systemConfiguration.utilities';
import { KEY_CODES } from '@/main/app.constants';

interface AiAssistantPromptProps {
  submitPrompt: (prompt: string) => void;
  isRunning: boolean;
}

export const AiAssistantPrompt: React.FunctionComponent<AiAssistantPromptProps> = ({ submitPrompt, isRunning }) => {
  const [validPrompt, setValidPrompt] = useState(false);
  const [prompt, setPrompt] = useState('');

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const promptValue = event.target.value;
    setValidPrompt(promptValue.trim().length > 0);
    setPrompt(promptValue);
  };

  const disableSubmit = () => {
    return isRunning || !validPrompt || !aiAssistantEnabled();
  };

  const onSubmit = () => {
    submitPrompt(prompt);
    setPrompt('');
  };

  const catchEnterToSubmit: KeyboardEventHandler = (e) => {
    if (!disableSubmit()) {
      if (e.keyCode === KEY_CODES.ENTER && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
        e.preventDefault();
        submitPrompt(prompt);
        setPrompt('');
      }
      if (e.keyCode === KEY_CODES.ENTER && (e.ctrlKey || e.metaKey || e.shiftKey)) {
        setPrompt(`${prompt}\r\n`);
      }
    }
  };

  return (
    <div className="flexColumnContainer flexAlignEnd p10 promptFooter">
      <TextAreaAutosize
        value={prompt}
        className={`promptInput flexFill p5 ${isRunning ? 'disabled' : ''}`}
        placeholder={
          isRunning ? t('FORMULA.ASSISTANT.RESPONDING_PROMPT_PLACEHOLDER') : t('FORMULA.ASSISTANT.PROMPT_PLACEHOLDER')
        }
        maxRows={7}
        minRows={1}
        onChange={onChange}
        onKeyDown={catchEnterToSubmit}
        data-testid="promptInput"
      />
      <Button
        variant="theme"
        onClick={onSubmit}
        disabled={disableSubmit()}
        size="sm"
        testId="submitPrompt"
        extraClassNames="pl8"
        iconPrefix={isRunning ? 'fa-solid' : ''}
        icon={isRunning ? 'fa-spinner fa-spin-pulse sq-icon-white' : 'fa-paper-plane sq-icon-white'}
      />
    </div>
  );
};
